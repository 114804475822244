import React, { useRef } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  Spacer,
  Box,
  Text,
} from "@chakra-ui/react";

import { useState } from "react";
import Tab from "../general/Tab";
import {
  FilterDrawer,
  FilterDateSelect,
  FilterNumber,
  FilterSelect,
  FilterButton,
  rangeToPill,
  dateRangeToPill,
} from "../filter";
import ExportToCsv from "./ExportButton";
import { useInfiniteQuery } from "react-query";
import { isMobile } from "react-device-detect";
import { useNavigate, useSearchParams } from "react-router-dom";
import TransferDetails from "../page/TransferDetails";
import { getTransfers } from "../../api/api";
import { Transfer, TransfersParams } from "../../api/types";
import { capitalize, fmtLCTMoney } from "../../globals/utils";
import moment from "moment";
import { Column } from "react-table";
import { Table } from "@alpacahq/alpaca-component-library";
import UUID from "../general/UUID";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

const BASE_CURRENCY = "USD";
const QUERY_LIMIT = 100;

const DIRECTION_OPTIONS = ["All", "INCOMING", "OUTGOING"];
const TRANSFER_TYPE_OPTIONS = ["All", "ach", "wire"];

interface TableProps {
  accountID?: string;
}

interface OnFetchDataProps {
  pageIndex: number;
  pageSize: number;
}

interface FormData {
  type: string;
  direction: string;
  amount_above: number | null;
  amount_below: number | null;
  updated_timeframe: string;
  updated_after: moment.Moment | null;
  updated_until: moment.Moment | null;
}

const TRANSFER_DIRECTION_VARIANT: { [key: string]: { color: string } } = {
  INCOMING: {
    color: "success",
  },
  OUTGOING: {
    color: "success",
  },
};

const TRANSFER_STATUS_VARIANT: {
  [key: string]: { color: string; icon?: JSX.Element };
} = {
  COMPLETE: {
    color: "success",
    icon: <CheckIcon />,
  },
  CANCELED: {
    color: "error",
    icon: <CloseIcon />,
  },
};

const defaultFilters: FormData = {
  type: "All",
  direction: "All",
  amount_above: null,
  amount_below: null,
  updated_timeframe: "anytime",
  updated_after: null,
  updated_until: null,
};

const createInitialFilters = (searchParams: URLSearchParams): FormData => {
  return {
    type: searchParams.get("type") ?? defaultFilters.type,
    direction: searchParams.get("direction") ?? defaultFilters.direction,
    amount_above: defaultFilters.amount_above,
    amount_below: defaultFilters.amount_below,
    updated_timeframe:
      searchParams.get("updated_timeframe") ?? defaultFilters.updated_timeframe,
    updated_after: searchParams.get("updated_after")
      ? moment(searchParams.get("updated_after"))
      : defaultFilters.updated_after,
    updated_until: searchParams.get("updated_until")
      ? moment(searchParams.get("updated_until"))
      : defaultFilters.updated_until,
  };
};

const TransactionsTable = ({ accountID }: TableProps): React.ReactElement => {
  const navigate = useNavigate();
  const skipPageResetRef = useRef(false);

  const [selectedTransfer, setSelectedTransfer] = useState<
    Transfer | undefined
  >();

  const [filterOpen, setFilterOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState(() =>
    createInitialFilters(searchParams)
  );

  const [appliedFilters, setAppliedFilters] = useState(() =>
    createInitialFilters(searchParams)
  );

  const [selectedTab, setSelectedTab] = useState(0);

  const fetchTransfers = ({ pageParam = 0 }) => {
    const params: TransfersParams = {
      limit: QUERY_LIMIT,
      offset: pageParam * QUERY_LIMIT,
      transfer_type: appliedFilters.type,
      direction: appliedFilters.direction,
    };

    // date filter
    if (appliedFilters.updated_after && appliedFilters.updated_until) {
      params.updated_after = appliedFilters.updated_after
        .startOf("day")
        .toISOString();
      params.updated_before = appliedFilters.updated_until
        .endOf("day")
        .toISOString();
    }

    // amount filter
    if (appliedFilters.amount_above && appliedFilters.amount_below) {
      params.amount_above = appliedFilters.amount_above;
      params.amount_below = appliedFilters.amount_below;
    }

    return getTransfers(params, accountID);
  };

  const query = useInfiniteQuery(
    ["transfers", accountID, appliedFilters],
    fetchTransfers,
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length === QUERY_LIMIT) {
          return pages.length;
        }
      },
    }
  );

  const transfers = (query.data?.pages ?? []).flat();

  const applyFilter = () => {
    setAppliedFilters(filters);
    updateSearchParams(filters);
    setFilterOpen(false);
  };

  const removeFilter = (filterKey: string) => {
    setAppliedFilters((filters) => {
      const updatedFilters = { ...filters };

      if (filterKey === "type") {
        updatedFilters.type = defaultFilters.type;
      }

      if (filterKey === "direction") {
        updatedFilters.direction = defaultFilters.direction;
      }

      if (filterKey === "amount") {
        updatedFilters.amount_above = defaultFilters.amount_above;
        updatedFilters.amount_below = defaultFilters.amount_below;
      }

      if (filterKey === "updated_at") {
        updatedFilters.updated_timeframe = defaultFilters.updated_timeframe;
        updatedFilters.updated_after = defaultFilters.updated_after;
        updatedFilters.updated_until = defaultFilters.updated_until;
      }

      updateSearchParams(updatedFilters);

      return updatedFilters;
    });
  };

  const filterPills = {
    type: appliedFilters.type,
    direction: appliedFilters.direction,
    amount: rangeToPill("amount", [
      appliedFilters.amount_above,
      appliedFilters.amount_below,
    ]),
    updated_at: dateRangeToPill([
      appliedFilters.updated_after,
      appliedFilters.updated_until,
    ]),
  };

  const accountColumns: Column<Transfer>[] = [
    {
      Header: "Account ID",
      accessor: ({ account_id }) => (
        <UUID
          value={account_id}
          onClick={() => navigate(`/accounts/${account_id}`)}
          isTruncated
        />
      ),
    },
    {
      Header: "Account Number",
      accessor: ({ account_number, account_id }) => (
        <UUID
          value={account_number}
          onClick={() => navigate(`/accounts/${account_id}`)}
          isTruncated
        />
      ),
    },
  ];

  const columns: Column<Transfer>[] = [
    ...(!accountID ? accountColumns : []),
    {
      Header: "Transaction ID",
      accessor: (tx) => (
        <UUID
          value={tx.id}
          onClick={() => setSelectedTransfer(tx)}
          isTruncated
        />
      ),
    },
    {
      Header: "Direction",
      accessor: ({ direction }) => (
        <Text color={TRANSFER_DIRECTION_VARIANT[direction]?.color ?? "inherit"}>
          {capitalize(direction)}
        </Text>
      ),
    },
    {
      Header: "Type",
      accessor: ({ type }) => type?.toUpperCase(),
    },
    {
      Header: "Amount",
      accessor: ({ amount }) => fmtLCTMoney(amount, BASE_CURRENCY),
    },
    {
      Header: "Status",
      accessor: ({ status }) => (
        <Text color={TRANSFER_STATUS_VARIANT[status]?.color ?? "inherit"}>
          {TRANSFER_STATUS_VARIANT[status]?.icon} {status}
        </Text>
      ),
    },
    {
      Header: "Updated At",
      accessor: ({ updated_at }) => {
        return (
          <Flex>
            <Text>{updated_at?.format("YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>&nbsp;{updated_at?.format("hh:mm a")}</Text>
          </Flex>
        );
      },
    },
  ];

  const getTablePanel = (rows: Transfer[]) => (
    <Table
      columns={columns}
      data={rows}
      defaultPerPage={25}
      pagination
      paginationPosition="top"
      isLoading={query.isLoading}
      onFetchData={({ pageIndex, pageSize }: OnFetchDataProps) => {
        if ((pageIndex + 1) * pageSize === rows.length) {
          query.fetchNextPage();
          skipPageResetRef.current = true;
        }
      }}
      skipPageResetRef={skipPageResetRef}
    />
  );

  const openFilter = () => {
    setFilters(appliedFilters);
    setFilterOpen(true);
  };

  const closeFilter = () => {
    setFilterOpen(false);
  };

  const setFilterValue = (key: keyof FormData, value: FormData[typeof key]) => {
    setFilters((filters) => ({
      ...filters,
      [key]: value,
    }));
  };

  const updateSearchParams = (filters: FormData) => {
    const searchParams = new URLSearchParams();

    if (filters.type !== "All") {
      searchParams.set("type", filters.type);
    }

    if (filters.direction !== "All") {
      searchParams.set("direction", filters.direction);
    }

    if (filters.updated_timeframe !== "anytime") {
      searchParams.set("updated_timeframe", filters.updated_timeframe);
      searchParams.set(
        "updated_after",
        filters.updated_after?.format("YYYY-MM-DD") ?? ""
      );
      searchParams.set(
        "updated_until",
        filters.updated_until?.format("YYYY-MM-DD") ?? ""
      );
    }

    setSearchParams(searchParams);
  };

  const filterButton = accountID ? (
    <Box mt="-6.5rem" float="right">
      <FilterButton
        filterPills={filterPills}
        removeFilter={removeFilter}
        openFilter={openFilter}
      />
    </Box>
  ) : (
    <Flex>
      <Spacer />
      <Box mb="1rem">
        <FilterButton
          filterPills={filterPills}
          removeFilter={removeFilter}
          openFilter={openFilter}
        />
      </Box>
    </Flex>
  );

  return (
    <>
      <TransferDetails
        isOpen={!!selectedTransfer}
        onClose={() => setSelectedTransfer(undefined)}
        transfer={selectedTransfer}
      />
      <FilterDrawer
        isOpen={filterOpen}
        onApply={applyFilter}
        onClose={closeFilter}
      >
        <FilterSelect
          header="Transfer Type"
          options={TRANSFER_TYPE_OPTIONS}
          onSelect={(value) => setFilterValue("type", value)}
          selected={filters.type}
        />
        <FilterSelect
          header="Direction"
          options={DIRECTION_OPTIONS}
          onSelect={(value) => setFilterValue("direction", value)}
          selected={filters.direction}
        />
        <FilterNumber
          header="Amount"
          minRange={0}
          maxRange={100000}
          step={100}
          onChange={([above, below]) => {
            setFilterValue("amount_above", above);
            setFilterValue("amount_below", below);
          }}
        />
        <FilterDateSelect
          header="Updated At"
          onSelect={(value) => setFilterValue("updated_timeframe", value)}
          defaultSelect={filters.updated_timeframe}
          onDateSelect={(start, end) => {
            setFilterValue("updated_after", start);
            setFilterValue("updated_until", end);
          }}
          value={[filters.updated_after, filters.updated_until]}
        />
      </FilterDrawer>
      {(isMobile || accountID) && filterButton}
      {accountID ? (
        getTablePanel(transfers)
      ) : (
        <Tabs onChange={(idx) => setSelectedTab(idx)}>
          <Flex>
            <TabList>
              <Tab text="Recent" isSelected={selectedTab === 0} />
              <Tab text="Deposit" isSelected={selectedTab === 1} />
              <Tab text="Withdrawals" isSelected={selectedTab === 2} />
            </TabList>
            <Spacer />
            <Box mb="1rem" alignSelf="center">
              <ExportToCsv
                data={transfers}
                allData={[]}
                givenHeaders={columns.map(
                  (column) => column.Header?.toString() ?? ""
                )}
              />
            </Box>
            {!isMobile && filterButton}
          </Flex>
          <TabPanels>
            <TabPanel>{getTablePanel(transfers)}</TabPanel>
            <TabPanel>
              {getTablePanel(
                transfers.filter((tx) => tx.direction === "Incoming")
              )}
            </TabPanel>
            <TabPanel>
              {getTablePanel(
                transfers.filter((tx) => tx.direction === "Outgoing")
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </>
  );
};

export default TransactionsTable;
