import joi from "joi";
import DeleteTeamMember from "./DeleteTeamMember";

import React, { useEffect, useState } from "react";

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";

import { Role } from "../../api/types";
import { useMutation, useQuery } from "react-query";
import { joiResolver } from "@hookform/resolvers/joi";
import { Controller, useForm } from "react-hook-form";
import { EventType, getAmplitude } from "../../globals/amplitude";
import { editTeamMember, getSelf, inviteTeamMember } from "../../api/api";

import type { AxiosError } from "axios";
import type {
  AddTeamMemberRequest,
  TeamMember,
  TeamMemberForm,
} from "../../api/types";

const SCHEMA = joi.object({
  id: joi.string(),
  email: joi.string().trim().lowercase().required(),
  name: joi.string().trim().required(),
  role: joi.string().required(),
});

const INITIAL_FORM_DATA = {
  email: "",
  name: "",
  role: "",
};

interface SidebarProps {
  isOpen: boolean;
  onClose: (addAnother: boolean) => void;
  selectedMember: TeamMember | null;
}

const Sidebar = (props: SidebarProps): JSX.Element => {
  const { isOpen, onClose, selectedMember } = props;

  const {
    control,
    formState,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<TeamMemberForm>({
    mode: "onChange",
    resolver: joiResolver(SCHEMA),
    defaultValues: INITIAL_FORM_DATA,
  });

  const toast = useToast();
  const selectedID = watch("id");
  const selectedRole = watch("role");
  const [addAnother, setAddAnother] = useState<boolean>(false);

  const { data: authUser } = useQuery("self", () => getSelf());
  const addMutation = useMutation<void, AxiosError, AddTeamMemberRequest>(
    (payload) => inviteTeamMember(payload),
    {
      onSuccess: () => {
        handleClose();
        getAmplitude().track({
          event_type: EventType.ADD_TEAM_MEMBER_COMPLETED,
          event_properties: {
            url: "https://broker-app.alpaca.markets/team-settings",
          },
        });
        toast({
          title: "Team member added",
          status: "success",
        });
      },
      onError: (err) => {
        toast({
          title: "Unable to add team member",
          description: err.message,
          status: "error",
        });
      },
    }
  );

  const editMutation = useMutation<TeamMember, AxiosError, TeamMemberForm>(
    (payload) => editTeamMember(payload?.id ?? "", payload.name, payload.role),
    {
      onSuccess: (member) => {
        handleClose();
        toast({
          title: "Team member edited",
          status: "success",
        });

        if (member.id !== authUser?.id) {
          getAmplitude().track({
            event_type: EventType.EDIT_USER_PROFILE,
          });

          getAmplitude().track({
            event_type: EventType.EDIT_TEAM_MEMBER_COMPLETED,
            event_properties: {
              url: "https://broker-app.alpaca.markets/team-settings",
            },
          });
        }
      },
      onError: (err) => {
        toast({
          title: "Unable to edit team member",
          description: err.message,
          status: "error",
        });
      },
    }
  );

  const submitAddMutation = useMutation<void, AxiosError, AddTeamMemberRequest>(
    (payload) => inviteTeamMember(payload),
    {
      onSuccess: () => {
        setAddAnother(true);
        handleClose();
        toast({
          title: "Team member added",
          status: "success",
        });
      },
      onError: (err) => {
        toast({
          title: "Unable to add team member",
          description: err.message,
          status: "error",
        });
      },
    }
  );

  const isSubmitLoading = addMutation.isLoading || editMutation.isLoading;
  const isSubmitAddLoading = submitAddMutation.isLoading;
  const isLoading = isSubmitLoading || isSubmitAddLoading;
  const isSubmitDisabled = !formState.isValid || isLoading;

  const canEditRole = (role: string) => {
    if (!authUser || !authUser.role) return false;
    return (
      authUser.role === Role.Superuser ||
      (authUser.role === Role.Developer && role !== Role.Superuser)
    );
  };

  const handleClose = () => {
    reset(INITIAL_FORM_DATA);
    onClose(addAnother);
    setAddAnother(false);
  };

  const handleCloseButton = () => {
    handleClose();
    getAmplitude().track({
      event_type: selectedMember
        ? EventType.EDIT_TEAM_MEMBER_CANCELLED
        : EventType.ADD_TEAM_MEMBER_CANCELLED,
      event_properties: {
        url: "https://broker-app.alpaca.markets/team-settings",
      },
    });
  };

  const handleSubmitAdd = () => {
    setAddAnother(true);
    handleSubmit(onSubmitAdd)();
    getAmplitude().track({
      event_type: EventType.ADD_ANOTHER_USER_CHECKED,
    });
  };

  const onSubmit = (formData: TeamMemberForm) => {
    const mutation = selectedMember ? editMutation : addMutation;
    mutation.mutate(formData);
  };

  const onSubmitAdd = (formData: TeamMemberForm) => {
    submitAddMutation.mutate(formData);
  };

  useEffect(() => {
    const options = { shouldValidate: true };
    setValue("id", selectedMember?.id, options);
    setValue("email", selectedMember?.email ?? "", options);
    setValue("name", selectedMember?.name ?? "", options);
    setValue("role", selectedMember?.role ?? "", options);
  }, [selectedMember]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleCloseButton}
      placement="right"
      size="md"
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerHeader>
            {selectedMember ? "Edit Team Member" : "Add Team Member"}
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            <Text fontSize="md" fontWeight={500} ml="3px">
              Name
            </Text>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  type="name"
                  placeholder="Name"
                  variant="filled"
                  {...field}
                />
              )}
            />
            <Text fontSize="md" fontWeight={500} mt="2rem" ml="3px">
              Email
            </Text>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  type="email"
                  placeholder="Email Address"
                  disabled={!!selectedMember}
                  variant="filled"
                  {...field}
                />
              )}
            />
            <Text fontSize="md" fontWeight={500} mt="2rem" ml="3px">
              Role
            </Text>
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder="Role"
                  disabled={
                    (authUser || {}).id === selectedID ||
                    !canEditRole(selectedRole)
                  }
                  variant="filled"
                  {...field}
                >
                  <option
                    disabled={!canEditRole("superuser")}
                    value="superuser"
                  >
                    Super User
                  </option>
                  <option
                    disabled={!canEditRole("developer")}
                    value="developer"
                  >
                    Developer (Admin)
                  </option>
                  <option
                    disabled={!canEditRole("operations")}
                    value="operations"
                  >
                    Operations
                  </option>
                  <option disabled={!canEditRole("support")} value="support">
                    Support
                  </option>
                </Select>
              )}
            />
            {authUser && selectedID && authUser.id !== selectedID && (
              <DeleteTeamMember
                onSidebarClose={handleClose}
                selectedMember={selectedMember}
              />
            )}
          </DrawerBody>
          <DrawerFooter>
            <Flex columnGap={5} justifyContent="space-evenly" width="100%">
              {!selectedMember && (
                <Button
                  disabled={isSubmitDisabled}
                  isLoading={isSubmitAddLoading}
                  flex={1}
                  onClick={handleSubmitAdd}
                  variant="link"
                >
                  Submit &amp; Add Another
                </Button>
              )}
              <Button
                colorScheme="brand"
                disabled={isSubmitDisabled}
                isLoading={isSubmitLoading}
                flex={1}
                onClick={handleSubmit(onSubmit)}
                variant="solid"
              >
                {selectedMember ? "Save Changes" : "Add Team Member"}
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default Sidebar;
