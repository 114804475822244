import Tab from "../components/general/Tab";
import UUID from "../components/general/UUID";
import Header from "../components/layout/Header";
import AddUser from "../components/user/AddUser";
import ExportToCsv from "../components/tables/ExportButton";

import React, { useEffect, useMemo, useState, useRef } from "react";

import {
  capitalize,
  fmtLCTMoney,
  getAccountStatusColor,
  formatDate,
} from "../globals/utils";

import {
  FilterDrawer,
  FilterDateSelect,
  FilterSelect,
  FilterString,
  FilterButton,
  dateRangeToPill,
} from "../components/filter";

import {
  Tabs,
  TabList,
  TabPanels,
  Flex,
  Spacer,
  useToast,
  Input,
  Text,
  Box,
  Link,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";

import { Column, Cell } from "react-table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Account, AccountsParams, TradingAccount } from "../api/types";
import { getAccounts, getTradingAccount } from "../api/api";
import { Table, TableProps } from "@alpacahq/alpaca-component-library";
import { EventType, getAmplitude } from "../globals/amplitude";
import moment from "moment";

interface FormData {
  status: string;
  account_id: string;
  account_name: string;
  account_number: string;
  email: string;
  created_timeframe: string;
  created_after: moment.Moment | null;
  created_until: moment.Moment | null;
}

const inactiveAccounts = [
  "SUBMITTED",
  "ACTION_REQUIRED",
  "APPROVAL_PENDING",
  "APPROVED",
  "ACCOUNT_CLOSED",
  "REJECTED",
  "INACTIVE",
  "ONBOARDING",
  "SUBMISSION_FAILED",
];

const statusOptions = ["All", "ACTIVE", "ACCOUNT_UPDATED", ...inactiveAccounts];

const defaultFilters: FormData = {
  status: "All",
  account_id: "",
  account_name: "",
  account_number: "",
  email: "",
  created_timeframe: "anytime",
  created_after: null,
  created_until: null,
};

const createInitialFilters = (searchParams: URLSearchParams): FormData => {
  return {
    status: searchParams.get("status") ?? defaultFilters.status,
    account_id: searchParams.get("account_id") ?? defaultFilters.account_id,
    account_name:
      searchParams.get("account_name") ?? defaultFilters.account_name,
    account_number:
      searchParams.get("account_number") ?? defaultFilters.account_number,
    email: searchParams.get("email") ?? defaultFilters.email,
    created_timeframe:
      searchParams.get("created_timeframe") ?? defaultFilters.created_timeframe,
    created_after: searchParams.get("created_after")
      ? moment(searchParams.get("created_after"))
      : defaultFilters.created_after,
    created_until: searchParams.get("created_until")
      ? moment(searchParams.get("created_until"))
      : defaultFilters.created_until,
  };
};

const Users = (): React.ReactElement => {
  const toast = useToast();
  const navigate = useNavigate();

  const fetchRefId = useRef(0);
  const skipPageResetRef = useRef(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const [addUserOpen, setAddUserOpen] = useState(false);

  const { isOpen, onToggle } = useDisclosure();
  const [filters, setFilters] = useState<FormData>(() =>
    createInitialFilters(searchParams)
  );
  const [appliedFilters, setAppliedFilters] = useState<FormData>(() =>
    createInitialFilters(searchParams)
  );

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [allAccounts, setAllAccounts] = useState<Account[]>([]);

  // Fetch brokerage accounts
  const brokerAccountsResponse = useQuery<Account[], unknown>(
    ["accounts", appliedFilters],
    () => {
      const params: AccountsParams = {
        status: appliedFilters.status,
        id: appliedFilters.account_id,
        account_number: appliedFilters.account_number,
        query: [appliedFilters.account_name, appliedFilters.email]
          .filter((e) => e.trim() !== "")
          .join(","),
      };

      if (appliedFilters.created_after && appliedFilters.created_until) {
        params.created_after = appliedFilters.created_after
          .startOf("day")
          .toISOString();
        params.created_before = appliedFilters.created_until
          .endOf("day")
          .toISOString();
      }

      return getAccounts(params);
    },
    {
      onSuccess: (data) => {
        if (!Object.keys(appliedFilters).length) {
          setAllAccounts(data);
        }
      },
    }
  );

  // return a filtered list of accounts
  const brokerageAccounts = useMemo(() => {
    const data = brokerAccountsResponse.data ?? [];

    // filter by status if applicable
    switch (selectedTab) {
      case 1:
        return data.filter((account) => account.status === "ACTIVE");
      case 2:
        return data.filter((account) =>
          inactiveAccounts.includes(account.status)
        );
    }

    return data;
  }, [selectedTab, brokerAccountsResponse.data]);

  const brokerageAccountIDs = useMemo(
    () => brokerageAccounts.map((acct) => acct.id),
    [brokerageAccounts]
  );

  const tradingAccountsResponse = useQuery(
    ["trading-accounts", brokerageAccountIDs, page, pageSize],
    () =>
      Promise.all(
        brokerageAccounts
          ?.filter(
            (_, index) =>
              // If index is greater than or equal to page start index
              index >= (page - 1) * pageSize &&
              // If index is less than page end index
              index < page * pageSize
          )
          .map((account) => getTradingAccount(account.id))
      ),
    {
      select: (tradingAccounts) => {
        return tradingAccounts.reduce(
          (prev, acct) => ({
            ...prev,
            [acct.id]: acct,
          }),
          {} as Record<string, TradingAccount>
        );
      },
    }
  );

  useEffect(() => {
    if (brokerAccountsResponse.isError) {
      toast({
        title: "An error occurred fetching brokerage accounts",
        description: (brokerAccountsResponse.error as Error).message,
        status: "error",
      });
    }

    if (tradingAccountsResponse.isError) {
      toast({
        title: "An error occurred fetching trading accounts",
        description: (tradingAccountsResponse.error as Error).message,
        status: "error",
      });
    }
  }, [brokerAccountsResponse.isError, tradingAccountsResponse.isError]);

  useEffect(() => {
    getAmplitude().track({
      event_type: EventType.ACCOUNTS_PAGE_VISITED,
    });
  }, []);

  const columns: Column<Account>[] = [
    {
      Header: "Account Number",
      accessor: ({ id, account_number }) => (
        <UUID
          onClick={() => navigate(`/accounts/${id}`)}
          value={account_number}
        />
      ),
    },
    {
      Header: "Account ID",
      accessor: ({ id }) => {
        return (
          <UUID
            onClick={() => navigate(`/accounts/${id}`)}
            value={id}
            isTruncated
          />
        );
      },
    },
    {
      Header: "Name",
      accessor: ({ identity, name }) =>
        identity
          ? identity?.party_type === "natural_person"
            ? name
            : identity.entity_name
          : "",
    },
    {
      Header: "Equity",
      accessor: ({ id, currency }) =>
        fmtLCTMoney(tradingAccountsResponse.data?.[id]?.equity, currency),
    },
    {
      Header: "Last Equity",
      accessor: ({ id, currency }) =>
        fmtLCTMoney(tradingAccountsResponse.data?.[id]?.last_equity, currency),
    },
    {
      Header: "Address",
      Cell: ({ row: { original: account } }: Cell<Account>) => {
        const address = [
          ...(account?.contact?.street_address || []),
          account?.contact?.state,
          account?.contact?.postal_code,
          account?.identity?.country_of_tax_residence,
        ]
          .filter((field) => !!field)
          .join(", ");

        return (
          <Tooltip label={address} placement="top">
            <Text isTruncated maxWidth="300px">
              {address}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      Header: "Status",
      accessor: ({ status }) => (
        <Text color={getAccountStatusColor(status)}>{capitalize(status)}</Text>
      ),
    },
    {
      Header: "Crypto Status",
      accessor: ({ crypto_status }) => (
        <Text color={getAccountStatusColor(crypto_status)}>
          {capitalize(crypto_status || "-")}
        </Text>
      ),
    },
    {
      Header: "Cash Interest Status",
      accessor: ({ cash_interest }) => (
        <Text color={getAccountStatusColor(cash_interest?.USD?.status)}>
          {capitalize(cash_interest?.USD?.status || "-")}
        </Text>
      ),
    },
    {
      Header: "APR Tier",
      accessor: ({ cash_interest }) => (
        <Text>{capitalize(cash_interest?.USD?.apr_tier_name || "-")}</Text>
      ),
    },
    {
      Header: "Created At",
      accessor: ({ created_at }) => {
        return (
          <Flex>
            <Text>{formatDate(created_at, "YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>
              &nbsp;{formatDate(created_at, "hh:mm A z")}
            </Text>
          </Flex>
        );
      },
    },
  ];

  const filterPills = {
    status: capitalize(appliedFilters.status),
    created_at: dateRangeToPill([
      appliedFilters.created_after,
      appliedFilters.created_until,
    ]),
    account_id: appliedFilters.account_id,
    account_name: appliedFilters.account_name,
    account_number: appliedFilters.account_number,
    email: appliedFilters.email,
  };

  const removeFilter = (filterKey: string) => {
    setAppliedFilters((filters) => {
      const updatedFilters = { ...filters };

      if (filterKey === "status") {
        updatedFilters.status = defaultFilters.status;
      }

      if (filterKey === "account_id") {
        updatedFilters.account_id = defaultFilters.account_id;
      }

      if (filterKey === "account_name") {
        updatedFilters.account_name = defaultFilters.account_name;
      }

      if (filterKey === "account_number") {
        updatedFilters.account_number = defaultFilters.account_number;
      }

      if (filterKey === "email") {
        updatedFilters.email = defaultFilters.email;
      }

      if (filterKey === "created_at") {
        updatedFilters.created_timeframe = "anytime";
        updatedFilters.created_after = null;
        updatedFilters.created_until = null;
      }

      updateSearchParams(updatedFilters);

      return updatedFilters;
    });
  };

  const onFetchData: TableProps<Account>["onFetchData"] = ({
    pageIndex,
    pageSize,
  }) => {
    if (++fetchRefId.current !== fetchRefId.current) {
      return;
    }

    skipPageResetRef.current = true;

    setPage(pageIndex + 1);
    setPageSize(pageSize);
  };

  const tabSwitchAmplitudeEvent = (idx: number) => {
    if (idx > 0) {
      getAmplitude().track({
        event_type:
          idx === 1
            ? EventType.ACCOUNTS_PAGE_ACTIVE_USERS
            : EventType.ACCOUNTS_PAGE_INACTIVE_USERS,
      });
    }
  };

  const filterAccountsAmplitudeEvent = () => {
    if (appliedFilters.status !== "All") {
      getAmplitude().track({
        event_type: EventType.ACCOUNTS_PAGE_STATUS_FILTER,
      });
    }

    if (appliedFilters.created_after && appliedFilters.created_until) {
      getAmplitude().track({
        event_type: EventType.ACCOUNTS_PAGE_CREATED_AT_FILTER,
      });
    }
  };

  const onApply = () => {
    setAppliedFilters(filters);
    updateSearchParams(filters);
    onToggle();
    filterAccountsAmplitudeEvent();
  };

  const onOpen = () => {
    setFilters(appliedFilters);
    onToggle();
    getAmplitude().track({
      event_type: EventType.ACCOUNTS_PAGE_FILTER,
    });
  };

  const onClose = () => {
    onToggle();
    getAmplitude().track({
      event_type: EventType.ACCOUNTS_PAGE_CANCEL_FILTER,
    });
  };

  const setFilterValue = (
    name: keyof FormData,
    value: FormData[typeof name]
  ) => {
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const formatForExport = (data: Account[]) => {
    return data.map((account) => ({
      ...account,
      created_at: formatDate(account.created_at, "YYYY-MM-DDTHH:mm:ss.SSZ"),
    }));
  };

  const updateSearchParams = (filters: FormData) => {
    const searchParams = new URLSearchParams();

    if (filters.status !== "All") {
      searchParams.set("status", filters.status);
    }

    if (filters.account_id !== "") {
      searchParams.set("account_id", filters.account_id);
    }

    if (filters.account_name !== "") {
      searchParams.set("account_name", filters.account_name);
    }

    if (filters.account_number !== "") {
      searchParams.set("account_number", filters.account_number);
    }

    if (filters.email !== "") {
      searchParams.set("email", filters.email);
    }

    if (filters.created_timeframe !== "anytime") {
      searchParams.set("created_timeframe", filters.created_timeframe);
      searchParams.set(
        "created_after",
        filters.created_after?.format("YYYY-MM-DD") ?? ""
      );
      searchParams.set(
        "created_until",
        filters.created_until?.format("YYYY-MM-DD") ?? ""
      );
    }

    setSearchParams(searchParams);
  };

  return (
    <Box>
      <Header title="Accounts" />
      <Text>
        Learn more about our account opening process{" "}
        <Link
          href="https://alpaca.markets/docs/broker/integration/account-opening/"
          isExternal
        >
          here
        </Link>
      </Text>
      <FilterDrawer isOpen={isOpen} onClose={onClose} onApply={onApply}>
        <FilterSelect
          header="Status"
          options={statusOptions}
          onSelect={(value) => setFilterValue("status", value)}
          selected={filters.status}
        />
        <FilterDateSelect
          header="Created At"
          onSelect={(value) => setFilterValue("created_timeframe", value)}
          onDateSelect={(start, end) => {
            setFilterValue("created_after", start);
            setFilterValue("created_until", end);
          }}
          defaultSelect={filters.created_timeframe}
          value={[filters.created_after, filters.created_until]}
        />
        <FilterString
          header="Account ID"
          onChange={(value) => setFilterValue("account_id", value)}
          value={filters.account_id}
        />
        <FilterString
          header="Account Name"
          onChange={(value) => setFilterValue("account_name", value)}
          value={filters.account_name}
        />
        <FilterString
          header="Account Number"
          onChange={(value) => setFilterValue("account_number", value)}
          value={filters.account_number}
        />
        <FilterString
          header="Email Address"
          onChange={(value) => setFilterValue("email", value)}
          value={filters.email}
        />
      </FilterDrawer>
      <AddUser isOpen={addUserOpen} onClose={() => setAddUserOpen(false)}>
        <Input variant="filled" type="email" placeholder="Email" />
      </AddUser>
      <Tabs
        onChange={(idx) => {
          setSelectedTab(idx);
          tabSwitchAmplitudeEvent(idx);
        }}
        mt="3rem"
      >
        <Flex>
          <TabList>
            <Tab text="All Users" isSelected={selectedTab === 0} />
            <Tab text="Active Users" isSelected={selectedTab === 1} />
            <Tab text="Inactive Users" isSelected={selectedTab === 2} />
          </TabList>
          <Spacer />
          <ExportToCsv
            data={formatForExport(brokerageAccounts)}
            allData={formatForExport(allAccounts)}
            amplitudeEvent={EventType.ACCOUNTS_PAGE_EXPORT}
          />
          <FilterButton
            filterPills={filterPills}
            openFilter={onOpen}
            removeFilter={removeFilter}
          />
        </Flex>
        <TabPanels>
          <Table
            columns={columns}
            data={brokerageAccounts}
            onFetchData={onFetchData}
            defaultPerPage={pageSize}
            pagination
            paginationPosition="top"
            skipPageResetRef={skipPageResetRef}
            isLoading={
              brokerAccountsResponse.isLoading ||
              tradingAccountsResponse.isLoading
            }
          />
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Users;
