import React, { useState } from "react";
import styled from "@emotion/styled";

import {
  Alert,
  AlertIcon,
  Box,
  useToast,
  Flex,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "react-query";

import TicketTable from "./table";
import Header from "../../components/layout/Header";
import { getCorrespondent } from "../../api/api";
import {
  FilterButton,
  FilterSelect,
  FilterDrawer,
} from "../../components/filter";
import { capitalize } from "../../globals/utils";

interface FormData {
  status: string;
}

const Wrapper = styled(Box)`
  table > tbody > tr > td:nth-of-type(5) {
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const statusOptions = [
  "all",
  "new",
  "open",
  "pending",
  "hold",
  "solved",
  "closed",
];

const defaultFilters: FormData = {
  status: "all",
};

const SupportTickets = (): JSX.Element => {
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const [filters, setFilters] = useState<FormData>(defaultFilters);
  const [appliedFilters, setAppliedFilters] = useState<FormData>(
    defaultFilters
  );

  const { data, isLoading } = useQuery(
    "correspondent",
    () => getCorrespondent(),
    {
      onError: (err: Error) => {
        toast({
          title: "An error occurred fetching correspondent",
          description: err.message,
          status: "error",
        });
      },
    }
  );

  const openDrawer = () => {
    setFilters(appliedFilters);
    onToggle();
  };

  const closeDrawer = () => {
    onToggle();
  };

  const applyFilter = () => {
    setAppliedFilters(filters);
    onToggle();
  };

  const removeFilter = (filterKey: string) => {
    setAppliedFilters((filter) => ({
      ...filter,
      [filterKey]: defaultFilters[filterKey as keyof FormData],
    }));
  };

  const setFilterValue = (
    name: keyof FormData,
    value: FormData[typeof name]
  ) => {
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const filterPills = {
    status: capitalize(appliedFilters.status),
  };

  return (
    <Wrapper>
      <Header title="Support > Tickets" />
      {!isLoading && !data?.zendesk_organization_id && (
        <Alert status="warning" maxW="750px" mb="0.5rem">
          <AlertIcon /> There is no associated Zendesk Organization ID with this
          account.
        </Alert>
      )}
      <Flex mt="3rem">
        <Spacer />
        <FilterButton
          filterPills={filterPills}
          openFilter={openDrawer}
          removeFilter={removeFilter}
        />
      </Flex>
      <TicketTable
        enabled={!!data?.zendesk_organization_id}
        status={
          appliedFilters.status !== "all" ? appliedFilters.status : undefined
        }
      />
      <FilterDrawer isOpen={isOpen} onClose={closeDrawer} onApply={applyFilter}>
        <FilterSelect
          header="Status"
          options={statusOptions}
          onSelect={(status) => setFilterValue("status", status)}
          selected={filters.status}
        />
      </FilterDrawer>
    </Wrapper>
  );
};

export default SupportTickets;
