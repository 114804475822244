import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { Table } from "@alpacahq/alpaca-component-library";
import { Box, useDisclosure } from "@chakra-ui/react";

import numeral from "numeral";
import moment from "moment-timezone";

import UUID from "../general/UUID";
import {
  FilterButton,
  FilterDateSelect,
  FilterDrawer,
  FilterSelect,
  dateRangeToPill,
} from "../filter";
import { getActivities } from "../../api/api";
import { activityTypes, ListActivitiesParams, Activity } from "../../api/types";
import { fmtLCTMoney } from "../../globals/utils";

interface ActivitiesTableProps {
  accountID: string;
  nonTradeOnly: boolean;
  currency?: string;
}

interface FormData {
  type: string;
  updated_after: moment.Moment | null;
  updated_until: moment.Moment | null;
  updated_timeframe: string;
}

const DEFAULT_PAGE_SIZE = 25;

const defaultFilters: FormData = {
  type: "All",
  updated_after: null,
  updated_until: null,
  updated_timeframe: "anytime",
};

const createInitialFilters = (searchParams: URLSearchParams): FormData => {
  return {
    type: searchParams.get("type") ?? defaultFilters.type,
    updated_timeframe:
      searchParams.get("updated_timeframe") ?? defaultFilters.updated_timeframe,
    updated_after: searchParams.get("updated_after")
      ? moment(searchParams.get("updated_after"))
      : defaultFilters.updated_after,
    updated_until: searchParams.get("updated_until")
      ? moment(searchParams.get("updated_until"))
      : defaultFilters.updated_until,
  };
};

const ActivitiesTable = ({
  accountID,
  currency,
  nonTradeOnly,
}: ActivitiesTableProps): React.ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<FormData>(() =>
    createInitialFilters(searchParams)
  );
  const [appliedFilters, setAppliedFilters] = useState<FormData>(() =>
    createInitialFilters(searchParams)
  );

  const tradingColumns: Column<Activity>[] = [
    {
      Header: "Symbol",
      accessor: ({ symbol }) => symbol ?? "-",
    },
    {
      Header: "Qty",
      accessor: ({ qty, side }) => {
        if (!qty) {
          return "-";
        }
        const formattedQty = side === "sell" ? -qty : qty;
        return numeral(formattedQty).format("0,0.[00]");
      },
    },
    {
      Header: "$/Share",
      accessor: ({ price }) => (!!price ? fmtLCTMoney(price, currency) : "-"),
    },
  ];

  const columns: Column<Activity>[] = [
    {
      Header: "Activity Type",
      accessor: "activity_type",
    },
    {
      Header: "Activity ID",
      accessor: ({ id }) => {
        const activityID = id?.replace(/^[^:]+::/g, "");
        return <UUID value={activityID ?? ""} />;
      },
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Amount",
      accessor: ({ net_amount, side, qty, price }) => {
        const amount = (side === "sell" ? -1 : 1) * (qty ?? 0) * (price ?? 0);
        return fmtLCTMoney(net_amount ?? amount, currency);
      },
    },
    {
      Header: "Description",
      accessor: "description",
    },
    ...(!nonTradeOnly ? tradingColumns : []),
  ];

  const activitiesQuery = useQuery(
    ["activities", accountID, appliedFilters],
    () => {
      const params: ListActivitiesParams = {
        account_id: accountID,
      };

      if (appliedFilters.updated_after && appliedFilters.updated_until) {
        // Supposed we want to retrieve activities from 2022-09-09 (inclusive) to 2022-09-10 (inclusive),
        // we must set the query parameters `after` to 2022-09-09 00:00:00 and `until` to 2022-09-11 00:00:00
        params.after = moment
          .tz(
            appliedFilters.updated_after.format("YYYY-MM-DD HH:mm:ss"),
            "America/New_York"
          )
          .startOf("day")
          .format();
        params.until = moment
          .tz(
            appliedFilters.updated_until.format("YYYY-MM-DD HH:mm:ss"),
            "America/New_York"
          )
          .startOf("day")
          .add(1, "day")
          .format();
      }

      return getActivities(
        params,
        appliedFilters.type !== "All" ? appliedFilters.type : undefined
      );
    }
  );

  const activities = activitiesQuery.data ?? [];

  const filterPills = {
    type: appliedFilters.type,
    updated_at: dateRangeToPill([
      appliedFilters.updated_after,
      appliedFilters.updated_until,
    ]),
  };

  const applyFilter = () => {
    setAppliedFilters(filters);
    onClose();
  };

  const removeFilter = (filterKey: string) => {
    setAppliedFilters((filters) => {
      const updatedFilters = { ...filters };

      if (filterKey === "updated_at") {
        updatedFilters.updated_timeframe = defaultFilters.updated_timeframe;
        updatedFilters.updated_after = defaultFilters.updated_after;
        updatedFilters.updated_until = defaultFilters.updated_until;
      }

      if (filterKey === "type") {
        updatedFilters.type = defaultFilters.type;
      }

      return updatedFilters;
    });
  };

  const openFilter = () => {
    setFilters(appliedFilters);
    onOpen();
  };

  const closeFilter = () => {
    onClose();
  };

  const setFilterValue = (key: keyof FormData, value: FormData[typeof key]) => {
    setFilters((filters) => ({
      ...filters,
      [key]: value,
    }));
  };

  const updateSearchParams = (filters: FormData) => {
    const searchParams = new URLSearchParams();

    if (filters.type !== "All") {
      searchParams.set("type", filters.type);
    }

    if (filters.updated_timeframe !== "anytime") {
      searchParams.set("updated_timeframe", filters.updated_timeframe);
      searchParams.set(
        "updated_after",
        filters.updated_after?.format("YYYY-MM-DD") ?? ""
      );
      searchParams.set(
        "updated_until",
        filters.updated_until?.format("YYYY-MM-DD") ?? ""
      );
    }

    setSearchParams(searchParams);
  };

  useEffect(() => {
    updateSearchParams(appliedFilters);
  }, [appliedFilters]);

  return (
    <>
      <Box mt="-6.5rem" float="right">
        <FilterButton
          filterPills={filterPills}
          openFilter={openFilter}
          removeFilter={removeFilter}
        />
      </Box>
      <FilterDrawer isOpen={isOpen} onClose={closeFilter} onApply={applyFilter}>
        <FilterSelect
          header="Type"
          options={["All", ...activityTypes]}
          noOptionFmt
          onSelect={(value) => setFilterValue("type", value)}
          selected={filters.type}
        />
        <FilterDateSelect
          header="Updated At"
          onSelect={(value) => setFilterValue("updated_timeframe", value)}
          defaultSelect={filters.updated_timeframe}
          onDateSelect={(start, end) => {
            setFilterValue("updated_after", start);
            setFilterValue("updated_until", end);
          }}
          value={[filters.updated_after, filters.updated_until]}
        />
      </FilterDrawer>
      <Table
        columns={columns}
        data={activities}
        defaultPerPage={DEFAULT_PAGE_SIZE}
        pagination
        paginationPosition="top"
        isLoading={activitiesQuery.isLoading}
      />
    </>
  );
};

export default ActivitiesTable;
