import React, { useState } from "react";
import { Column } from "react-table";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

import { Table } from "@alpacahq/alpaca-component-library";
import { Box, useDisclosure } from "@chakra-ui/react";

import {
  FilterButton,
  FilterDrawer,
  FilterSelect,
  FilterString,
} from "../filter";
import { getPositions } from "../../api/api";
import { PositionsQuery, Position } from "../../api/types";
import { fmtLCTMoney } from "../../globals/utils";

interface PositionTableProps {
  accountID: string;
  currency?: string;
}

interface FormData {
  side: string;
  symbols: string;
}

const POSITION_SIDE_OPTIONS = ["all", "long", "short"];
const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_FILTERS: FormData = {
  side: "all",
  symbols: "",
};

const createInitialFilters = (searchParams: URLSearchParams): FormData => {
  return {
    side: searchParams.get("side") ?? DEFAULT_FILTERS.side,
    symbols: searchParams.get("symbols") ?? DEFAULT_FILTERS.symbols,
  };
};

const PositionsTable = ({
  accountID,
  currency,
}: PositionTableProps): React.ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<FormData>(() =>
    createInitialFilters(searchParams)
  );

  const [appliedFilters, setAppliedFilters] = useState(() =>
    createInitialFilters(searchParams)
  );

  const { data: positions = [], isLoading } = useQuery(
    ["positions", accountID, appliedFilters],
    () => {
      const params: PositionsQuery = {};

      if (appliedFilters.side !== "all") {
        params.side = appliedFilters.side;
      }

      if (appliedFilters.symbols) {
        params.symbols = appliedFilters.symbols;
      }

      return getPositions(params, accountID);
    }
  );

  const openFilter = () => {
    onOpen();
    setFilters(appliedFilters);
  };

  const closeFilter = () => {
    onClose();
  };

  const applyFilter = () => {
    setAppliedFilters(filters);
    updateSearchParams(filters);
    onClose();
  };

  const removeFilter = (filterKey: string) => {
    setAppliedFilters((filters) => {
      const updatedFilters = { ...filters };

      if (filterKey === "side") {
        updatedFilters.side = DEFAULT_FILTERS.side;
      }

      if (filterKey === "symbols") {
        updatedFilters.symbols = DEFAULT_FILTERS.symbols;
      }

      updateSearchParams(updatedFilters);

      return updatedFilters;
    });
  };

  const setFilterValue = (key: keyof FormData, value: FormData[typeof key]) => {
    setFilters((filters) => ({
      ...filters,
      [key]: value,
    }));
  };

  const updateSearchParams = (filters: FormData) => {
    const searchParams = new URLSearchParams();

    if (filters.side !== "all") {
      searchParams.set("side", filters.side);
    }

    if (filters.symbols !== "") {
      searchParams.set("symbols", filters.symbols);
    }

    setSearchParams(searchParams);
  };

  const filterPills = {
    side: appliedFilters.side,
    symbols: appliedFilters.symbols,
  };

  const columns: Column<Position>[] = [
    {
      Header: "Symbol",
      accessor: "symbol",
    },
    {
      Header: "Side",
      accessor: "side",
    },
    {
      Header: "Qty",
      accessor: "qty",
    },
    {
      Header: "Market Value",
      accessor: ({ market_value }) => fmtLCTMoney(market_value, currency),
    },
    {
      Header: "Unrealized P/L",
      accessor: ({ unrealized_pl }) => fmtLCTMoney(unrealized_pl, currency),
    },
  ];

  return (
    <>
      <Box mt="-6.5rem" float="right">
        <FilterButton
          filterPills={filterPills}
          openFilter={openFilter}
          removeFilter={removeFilter}
        />
      </Box>
      <FilterDrawer isOpen={isOpen} onClose={closeFilter} onApply={applyFilter}>
        <FilterSelect
          header="Side"
          options={POSITION_SIDE_OPTIONS}
          onSelect={(side) => setFilterValue("side", side)}
          selected={filters.side}
        />
        <FilterString
          header="Symbol"
          onChange={(symbol) => setFilterValue("symbols", symbol)}
          value={filters.symbols}
        />
      </FilterDrawer>
      <Table
        columns={columns}
        data={positions}
        isLoading={isLoading}
        defaultPerPage={DEFAULT_PAGE_SIZE}
        pagination
        paginationPosition="top"
      />
    </>
  );
};

export default PositionsTable;
