import React, { useState, useMemo } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Button,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import Header from "../components/layout/Header";
import {
  closeAccount,
  getAccount,
  getCorrespondent,
  getSelf,
  listFPSLTiers,
} from "../api/api";
import Tab from "../components/general/Tab";
import UserOverview from "../components/user/UserOverview";
import AccountDocumentsTable from "../components/tables/AccountDocumentsTable";
import { isMobile } from "react-device-detect";
import OrdersTable from "../components/tables/OrdersTable";
import TransactionsTable from "../components/tables/TransactionsTable";
import PositionsTable from "../components/tables/PositionsTable";
import ActivitiesTable from "../components/tables/ActivitiesTable";
import UploadedDocumentsTable from "../components/tables/UploadedDocumentsTable";
import AccountMargin from "../components/user/AccountMargin";
import { Account, Role, FPSLTier } from "../api/types";
import { AxiosError } from "axios";
import { CloseAccountModal } from "../components/user/CloseAccountModal";

const CLOSE_ACCOUNT_ROLES: Role[] = [Role.Superuser, Role.Operations];

const isAccountCloseable = (role?: Role) =>
  Boolean(role && CLOSE_ACCOUNT_ROLES.includes(role));

const User = (): React.ReactElement => {
  const { accountID } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const { data: account, refetch: refetchAccount } = useQuery(
    ["account", accountID],
    () => getAccount(accountID)
  );

  const { data: correspondent } = useQuery("correspondent", () =>
    getCorrespondent()
  );

  const tradingCurrency = correspondent?.lct_currency ?? "USD";

  const { data: authUser } = useQuery("self", () => getSelf());

  const { data: fpslTiers } = useQuery(["fpsl-tiers"], () => listFPSLTiers());

  const fpslTierMap = useMemo(() => {
    return (fpslTiers ?? []).reduce(
      (obj, tier) => ({ ...obj, [tier.id]: tier }),
      {} as Record<string, FPSLTier>
    );
  }, [fpslTiers]);

  const {
    isOpen: isCloseAccountOpen,
    onOpen: onCloseAccountOpen,
    onClose: onCloseAccountClose,
  } = useDisclosure();

  const toast = useToast();

  const mutation = useMutation<void, AxiosError, string>(
    (accountID: string) => closeAccount(accountID),
    {
      onSuccess: () => {
        onCloseAccountClose();
        toast({
          title: "Account closed",
          status: "success",
        });
        refetchAccount();
      },
      onError: (err) => {
        toast({
          title: "Unable to close account",
          description: err.message,
          status: "error",
        });
      },
    }
  );

  const onConfirmCloseAccount = () => {
    mutation.mutate(accountID);
  };

  const currency = account?.currency;
  const canCloseAccount = isAccountCloseable(
    authUser?.role as Role | undefined
  );

  return (
    <>
      <Header title={`Accounts > ${account?.account_number || ""}`} />
      {canCloseAccount && (
        <Button
          onClick={onCloseAccountOpen}
          float="right"
          marginRight="1rem"
          marginTop={["1rem", "0"]}
        >
          Close Account
        </Button>
      )}
      <Tabs onChange={(idx) => setSelectedTab(idx)} mt="5rem">
        <TabList>
          <Tab text="Overview" isSelected={selectedTab === 0} />
          <Tab text="Account Documents" isSelected={selectedTab === 1} />
          <Tab text="Uploaded Documents" isSelected={selectedTab === 2} />
          <Tab text="Funding" isSelected={selectedTab === 3} />
          {!isMobile && (
            <>
              <Tab text="Positions" isSelected={selectedTab === 4} />
              <Tab text="Orders" isSelected={selectedTab === 5} />
              <Tab text="Activities" isSelected={selectedTab === 6} />
              <Tab text="Margin" isSelected={selectedTab === 7} />
            </>
          )}
        </TabList>
        {isMobile && (
          <TabList>
            <Tab text="Positions" isSelected={selectedTab === 4} />
            <Tab text="Orders" isSelected={selectedTab === 5} />
            <Tab text="Activities" isSelected={selectedTab === 6} />
            <Tab text="Margin" isSelected={selectedTab === 7} />
          </TabList>
        )}
        <TabPanels>
          <TabPanel>
            <UserOverview
              account={
                // Assign the accountID since it's already known
                // The child component will use the accountID to fetch the trading account data
                { ...account, id: accountID } as Account
              }
              fpslTiers={fpslTierMap}
            />
          </TabPanel>
          <TabPanel>
            <AccountDocumentsTable accountID={accountID} />
          </TabPanel>
          <TabPanel>
            <UploadedDocumentsTable accountID={accountID} />
          </TabPanel>
          <TabPanel>
            <TransactionsTable accountID={accountID} />
          </TabPanel>
          <TabPanel>
            <PositionsTable accountID={accountID} currency={currency} />
          </TabPanel>
          <TabPanel>
            <OrdersTable accountID={accountID} currency={tradingCurrency} />
          </TabPanel>
          <TabPanel>
            <ActivitiesTable
              accountID={accountID}
              currency={currency}
              nonTradeOnly
            />
          </TabPanel>
          <TabPanel>
            <AccountMargin />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <CloseAccountModal
        accountID={accountID}
        isOpen={isCloseAccountOpen}
        onClose={onCloseAccountClose}
        onConfirm={onConfirmCloseAccount}
      />
    </>
  );
};

export default User;
