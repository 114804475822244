import moment from "moment";
import Tab from "../components/general/Tab";
import UUID from "../components/general/UUID";
import Header from "../components/layout/Header";
import ExportToCsv from "../components/tables/ExportButton";

import React, { useState, useRef, useEffect } from "react";

import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  Spacer,
  useToast,
  Box,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import {
  FilterDrawer,
  dateRangeToPill,
  FilterDateSelect,
  FilterSelect,
  FilterString,
  FilterButton,
} from "../components/filter";

import { Column } from "react-table";
import { getJournals } from "../api/api";
import { capitalize, fmtLCTMoney, formatDate } from "../globals/utils";
import { useInfiniteQuery } from "react-query";
import { isMobile } from "react-device-detect";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Table } from "@alpacahq/alpaca-component-library";
import { CheckIcon, CloseIcon, TimeIcon } from "@chakra-ui/icons";
import { Journal, JournalStatus, JournalType } from "../api/types";

interface FormData {
  id: string;
  status: JournalStatus;
  settled_timeframe: string;
  settled_after: moment.Moment | null;
  settled_until: moment.Moment | null;
}

const defaultFilters: FormData = {
  id: "",
  status: "all",
  settled_timeframe: "anytime",
  settled_after: null,
  settled_until: null,
};

const DEFAULT_PAGE_SIZE = 25;

const JOURNAL_STATUS_OPTIONS = [
  "all",
  "pending",
  "queued",
  "executed",
  "canceled",
  "deleted",
  "rejected",
];

const createInitialFilters = (searchParams: URLSearchParams): FormData => {
  return {
    id: searchParams.get("id") ?? defaultFilters.id,
    status:
      (searchParams.get("status") as JournalStatus) ?? defaultFilters.status,
    settled_timeframe:
      searchParams.get("settled_timeframe") ?? defaultFilters.settled_timeframe,
    settled_after: searchParams.get("settled_after")
      ? moment(searchParams.get("settled_after"))
      : defaultFilters.settled_after,
    settled_until: searchParams.get("settled_until")
      ? moment(searchParams.get("settled_until"))
      : defaultFilters.settled_until,
  };
};

const Journals = (): React.ReactElement => {
  const toast = useToast();
  const navigate = useNavigate();
  const fetchRefId = useRef(0);
  const skipPageResetRef = useRef(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [allJournals, setAllJournals] = useState<Journal[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState(() =>
    createInitialFilters(searchParams)
  );

  const [appliedFilters, setAppliedFilters] = useState(() =>
    createInitialFilters(searchParams)
  );

  // Paginated query
  const fetchJournals = ({ pageParam = moment() }) => {
    const params = {
      entry_type: "JNLC" as JournalType,
      status: appliedFilters.status,
      before: pageParam.toISOString(),
      after: moment(pageParam).subtract(3, "months").toISOString(),
      id: appliedFilters.id,
    };

    if (appliedFilters.settled_after && appliedFilters.settled_until) {
      params.after = appliedFilters.settled_after.startOf("day").toISOString();
      params.before = appliedFilters.settled_until.endOf("day").toISOString();
    }

    return getJournals(params);
  };

  const journalsQuery = useInfiniteQuery(
    ["journals", appliedFilters],
    fetchJournals,
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.length !== 0) {
          return lastPage[lastPage.length - 1].system_date;
        }
      },
      onSuccess: (data) => {
        const res = data?.pages?.flat() || [];
        setAllJournals(res);
      },
    }
  );

  const journals = (journalsQuery.data?.pages ?? []).flat();

  if (journalsQuery.isError) {
    toast({
      title: "An error occurred fetching journal entries",
      description: (journalsQuery.error as Error).message,
      status: "error",
    });
  }

  const applyFilter = () => {
    setAppliedFilters(filters);
    onClose();
  };

  const removeFilter = (filterKey: string) => {
    setAppliedFilters((filters) => {
      const updatedFilters = { ...filters };

      if (filterKey === "id") {
        updatedFilters.id = defaultFilters.id;
      }

      if (filterKey === "status") {
        updatedFilters.status = defaultFilters.status;
      }

      if (filterKey === "settled_date") {
        updatedFilters.settled_timeframe = defaultFilters.settled_timeframe;
        updatedFilters.settled_after = defaultFilters.settled_after;
        updatedFilters.settled_until = defaultFilters.settled_until;
      }

      return updatedFilters;
    });
  };

  const openFilter = () => {
    setFilters(appliedFilters);
    onOpen();
  };

  const closeFilter = () => {
    onClose();
  };

  const filterPills = {
    id: appliedFilters.id,
    status: appliedFilters.status,
    settled_date: dateRangeToPill([
      appliedFilters.settled_after,
      appliedFilters.settled_until,
    ]),
  };

  const Toolbar = (
    <Box display="flex">
      <ExportToCsv data={journals} allData={allJournals} />
      <FilterButton
        filterPills={filterPills}
        openFilter={openFilter}
        removeFilter={removeFilter}
      />
    </Box>
  );

  const getTextColor = (text: string) => {
    const styling = {
      success: ["executed"],
      warning: ["queued", "sent_to_clearing", "pending"],
      error: ["rejected", "canceled", "refused", "deleted"],
    };

    if (styling.success.includes(text)) {
      return "success";
    } else if (styling.warning.includes(text)) {
      return "warning";
    } else if (styling.error.includes(text)) {
      return "error";
    }

    return "inherit";
  };

  const getTextIcon = (text: string) => {
    const styling = {
      success: ["executed"],
      warning: ["queued", "sent_to_clearing", "pending"],
      error: ["rejected", "canceled", "refused", "deleted"],
    };

    if (styling.success.includes(text)) {
      return <CheckIcon mr="4px" />;
    } else if (styling.warning.includes(text)) {
      return <TimeIcon mr="4px" />;
    } else if (styling.error.includes(text)) {
      return <CloseIcon mr="4px" />;
    }

    return null;
  };

  const columns: Column<Journal>[] = [
    {
      Header: "Journal ID",
      accessor: ({ id }) => <UUID value={id} />,
    },
    { Header: "Entry Type", accessor: "entry_type" },
    {
      Header: "From Account",
      accessor: ({ from_account }) => (
        <UUID
          onClick={() => navigate(`/accounts/${from_account}`)}
          value={from_account}
        />
      ),
    },
    {
      Header: "To Account",
      accessor: ({ to_account }) => (
        <UUID
          onClick={() => navigate(`/accounts/${to_account}`)}
          value={to_account}
        />
      ),
    },
    {
      Header: "Net Amount",
      accessor: ({ net_amount, currency }) => fmtLCTMoney(net_amount, currency),
    },
    {
      Header: "Status",
      accessor: ({ status }) => (
        <Text color={getTextColor(status)}>
          {getTextIcon(status)}
          {capitalize(status)}
        </Text>
      ),
    },
    {
      Header: "Settle Date",
      accessor: ({ settle_date }) => {
        if (!settle_date) {
          return <Text>-</Text>;
        }

        return (
          <Flex>
            <Text>{formatDate(settle_date, "YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>
              &nbsp;{formatDate(settle_date, "hh:mm a")}
            </Text>
          </Flex>
        );
      },
    },
  ];

  const onFetchData = () => {
    if (++fetchRefId.current !== fetchRefId.current) {
      return;
    }

    skipPageResetRef.current = true;
  };

  const setFilterValue = (key: keyof FormData, value: FormData[typeof key]) => {
    setFilters((filters) => ({
      ...filters,
      [key]: value,
    }));
  };

  const updateSearchParams = (filters: FormData) => {
    const searchParams = new URLSearchParams();

    if (filters.id !== "") {
      searchParams.set("id", filters.id);
    }

    if (filters.status !== "all") {
      searchParams.set("status", filters.status);
    }

    if (filters.settled_timeframe !== "anytime") {
      searchParams.set("settled_timeframe", filters.settled_timeframe);
      searchParams.set(
        "settled_after",
        filters.settled_after?.format("YYYY-MM-DD") ?? ""
      );
      searchParams.set(
        "settled_until",
        filters.settled_until?.format("YYYY-MM-DD") ?? ""
      );
    }

    setSearchParams(searchParams);
  };

  useEffect(() => {
    updateSearchParams(appliedFilters);
  }, [appliedFilters]);

  return (
    <Box>
      <FilterDrawer isOpen={isOpen} onApply={applyFilter} onClose={closeFilter}>
        <FilterString
          header="Journal ID"
          onChange={(value) => setFilterValue("id", value)}
          value={filters.id}
        />
        <FilterSelect
          header="Status"
          options={JOURNAL_STATUS_OPTIONS}
          onSelect={(value) => setFilterValue("status", value)}
          selected={filters.status}
        />
        <FilterDateSelect
          header="Settle Date"
          onSelect={(value) => setFilterValue("settled_timeframe", value)}
          defaultSelect={filters.settled_timeframe}
          onDateSelect={(start, end) => {
            setFilterValue("settled_after", start);
            setFilterValue("settled_until", end);
          }}
          value={[filters.settled_after, filters.settled_until]}
        />
      </FilterDrawer>
      <Header title="Transactions > Journals" />
      {isMobile && Toolbar}
      <Tabs>
        <Flex>
          <TabList>
            <Tab text="Cash" isSelected />
          </TabList>
          <Spacer />
          {!isMobile && Toolbar}
        </Flex>
        <TabPanels>
          <TabPanel>
            <Table
              columns={columns}
              isLoading={journalsQuery.isLoading}
              data={journals}
              defaultPerPage={DEFAULT_PAGE_SIZE}
              onFetchData={onFetchData}
              pagination
              paginationPosition="top"
              skipPageResetRef={skipPageResetRef}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Journals;
