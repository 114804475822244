import React, { useState } from "react";
import { Column } from "react-table";
import { useQuery } from "react-query";
import { Table } from "@alpacahq/alpaca-component-library";
import { CheckIcon, CloseIcon, TimeIcon } from "@chakra-ui/icons";
import { Box, Flex, Spacer, Text, useToast } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

import {
  FilterDrawer,
  dateRangeToPill,
  FilterDateSelect,
  FilterNumber,
  FilterSelect,
  FilterString,
  rangeToPill,
  FilterButton,
} from "../filter";
import ExportToCsv from "./ExportButton";
import OrderDetails from "../page/OrderDetails";
import UUID from "../general/UUID";

import { getOrders } from "../../api/api";
import { Order, OrdersParams } from "../../api/types";
import { capitalize } from "../../globals/utils";

interface OrdersTableProps {
  accountID?: string;
  currency: string;
}

export const ORDER_STATUSES = [
  "all",
  "accepted",
  "accepted_for_bidding",
  "calculated",
  "canceled",
  "done_for_day",
  "expired",
  "filled",
  "held",
  "new",
  "partially_filled",
  "pending_cancel",
  "pending_new",
  "pending_replace",
  "rejected",
  "replaced",
  "stopped",
  "suspended",
];

export const ORDER_SIDES = [
  "all",
  "buy",
  "buy_minus",
  "sell",
  "sell_short",
  "sell_short_exempt",
  "undisclosed",
  "cross",
  "cross_short",
];

const QUERY_LIMIT = 500;

const CSV_HEADERS = [
  "account_id",
  "id",
  "side",
  "symbol",
  "filled_qty",
  "notional",
  "status",
  "updated_at",
  "formatted_updated_at",
];

interface FormData {
  id: string;
  status: string;
  side: string;
  symbol: string;
  qty_above: number | null;
  qty_below: number | null;
  submitted_timeframe: string;
  submitted_after: moment.Moment | null;
  submitted_until: moment.Moment | null;
}

const defaultFilters: FormData = {
  id: "",
  status: "all",
  side: "all",
  symbol: "",
  qty_above: null,
  qty_below: null,
  submitted_timeframe: "14-days",
  submitted_after: moment().subtract(14, "days"),
  submitted_until: moment(),
};

const DEFAULT_PAGE_SIZE = 25;

const createInitialFilters = (searchParams: URLSearchParams): FormData => {
  return {
    id: searchParams.get("id") ?? defaultFilters.id,
    status: searchParams.get("status") ?? defaultFilters.status,
    side: searchParams.get("side") ?? defaultFilters.side,
    symbol: searchParams.get("symbol") ?? defaultFilters.symbol,
    qty_above: defaultFilters.qty_above,
    qty_below: defaultFilters.qty_below,
    submitted_timeframe:
      searchParams.get("submitted_timeframe") ??
      defaultFilters.submitted_timeframe,
    submitted_after: searchParams.get("submitted_after")
      ? moment(searchParams.get("submitted_after"))
      : defaultFilters.submitted_after,
    submitted_until: searchParams.get("submitted_until")
      ? moment(searchParams.get("submitted_until"))
      : defaultFilters.submitted_until,
  };
};

const OrdersTable = ({
  accountID,
  currency,
}: OrdersTableProps): React.ReactElement => {
  const toast = useToast();
  const navigate = useNavigate();

  const [filterOpen, setFilterOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>();
  const [filters, setFilters] = useState(() =>
    createInitialFilters(searchParams)
  );
  const [appliedFilters, setAppliedFilters] = useState(() =>
    createInitialFilters(searchParams)
  );

  // Paginated query
  const fetchOrders = () => {
    const params: OrdersParams = {
      id: appliedFilters.id,
      status: appliedFilters.status,
      limit: QUERY_LIMIT,
      side: appliedFilters.side,
      symbols: appliedFilters.symbol,
    };

    if (appliedFilters.qty_above) {
      params.qty_above = appliedFilters.qty_above;
    }

    if (appliedFilters.qty_below) {
      params.qty_below = appliedFilters.qty_below;
    }

    if (appliedFilters.submitted_after && appliedFilters.submitted_until) {
      params.after = appliedFilters.submitted_after
        .startOf("day")
        .toISOString();
      params.until = appliedFilters.submitted_until.endOf("day").toISOString();
    }

    return getOrders(params, accountID);
  };

  const ordersQuery = useQuery(
    ["orders", accountID, appliedFilters],
    fetchOrders,
    {
      onError: (err) => {
        toast({
          title: "An error occurred fetching orders",
          description: (err as Error).message,
          status: "error",
        });
      },
    }
  );

  const orders = ordersQuery.data ?? [];

  const filterPills = {
    id: appliedFilters.id,
    status: appliedFilters.status,
    side: appliedFilters.side,
    symbol: appliedFilters.symbol,
    qty: rangeToPill("qty", [
      appliedFilters.qty_above,
      appliedFilters.qty_below,
    ]),
    submitted_at: dateRangeToPill([
      appliedFilters.submitted_after,
      appliedFilters.submitted_until,
    ]),
  };

  const applyFilter = () => {
    setAppliedFilters(filters);
    updateSearchParams(filters);
    setFilterOpen(false);
  };

  const removeFilter = (filterKey: string) => {
    setAppliedFilters((filters) => {
      const updatedFilters = { ...filters };

      if (filterKey === "id") {
        updatedFilters.id = defaultFilters.id;
      }

      if (filterKey === "status") {
        updatedFilters.status = defaultFilters.status;
      }

      if (filterKey === "side") {
        updatedFilters.side = defaultFilters.side;
      }

      if (filterKey === "symbol") {
        updatedFilters.symbol = defaultFilters.symbol;
      }

      if (filterKey === "qty") {
        updatedFilters.qty_above = defaultFilters.qty_above;
        updatedFilters.qty_below = defaultFilters.qty_below;
      }

      if (filterKey === "submitted_at") {
        updatedFilters.submitted_timeframe = "anytime";
        updatedFilters.submitted_after = null;
        updatedFilters.submitted_until = null;
      }

      updateSearchParams(updatedFilters);

      return updatedFilters;
    });
  };

  const openFilter = () => {
    setFilters(appliedFilters);
    setFilterOpen(true);
  };

  const closeFilter = () => {
    setFilterOpen(false);
  };

  const setFilterValue = (key: keyof FormData, value: FormData[typeof key]) => {
    setFilters((filters) => ({
      ...filters,
      [key]: value,
    }));
  };

  const filterBtn = accountID ? (
    <Box mt="-6.5rem" float="right" display="flex">
      <ExportToCsv data={orders} allData={[]} givenHeaders={CSV_HEADERS} />
      <FilterButton
        filterPills={filterPills}
        openFilter={openFilter}
        removeFilter={removeFilter}
      />
    </Box>
  ) : (
    <Flex>
      <Spacer />
      <Box mb="1rem" display="flex">
        <ExportToCsv data={orders} allData={[]} givenHeaders={CSV_HEADERS} />
        <FilterButton
          filterPills={filterPills}
          openFilter={openFilter}
          removeFilter={removeFilter}
        />
      </Box>
    </Flex>
  );

  const getTextColor = (text: string) => {
    const styling = {
      success: [
        "new",
        "partially_filled",
        "filled",
        "done_for_day",
        "buy",
        "buy_minus",
        "calculated",
      ],
      warning: ["replaced", "pending_cancel"],
      error: [
        "canceled",
        "rejected",
        "expired",
        "sell",
        "sell_short",
        "sell_short_exempt",
        "stopped",
      ],
    };

    if (styling.success.includes(text)) {
      return "success";
    } else if (styling.warning.includes(text)) {
      return "warning";
    } else if (styling.error.includes(text)) {
      return "error";
    }

    return "inherit";
  };

  const getTextIcon = (text: string) => {
    const styling = {
      success: ["filled"],
      warning: ["pending", "held", "pending_cancel", "pending_new", "expired"],
      failed: ["failed", "canceled", "rejected"],
    };

    if (styling.success.includes(text)) {
      return <CheckIcon mr="4px" />;
    } else if (styling.warning.includes(text)) {
      return <TimeIcon mr="4px" />;
    } else if (styling.failed.includes(text)) {
      return <CloseIcon mr="4px" />;
    }

    return null;
  };

  const columns: Column<Order>[] = [
    {
      Header: "Account ID",
      accessor: ({ account_id }) => (
        <UUID
          onClick={() => navigate(`/accounts/${account_id ?? accountID}`)}
          value={account_id ?? accountID}
          isTruncated
        />
      ),
    },
    {
      Header: "Account Number",
      accessor: ({ account_id, account_number }) => (
        <UUID
          onClick={() => navigate(`/accounts/${account_id ?? accountID}`)}
          value={account_number}
        />
      ),
    },
    {
      Header: "Order ID",
      accessor: (order) => (
        <UUID
          onClick={() => setSelectedOrder(order)}
          value={order.id}
          isTruncated
        />
      ),
    },
    {
      Header: "Side",
      accessor: ({ side }) => (
        <Text color={getTextColor(side)}>{capitalize(side)}</Text>
      ),
    },
    { Header: "Symbol", accessor: "symbol" },
    { Header: "Filled Qty", accessor: "filled_qty" },
    {
      Header: "Status",
      accessor: ({ status }) => (
        <Text color={getTextColor(status)}>
          {getTextIcon(status)}
          {capitalize(status)}
        </Text>
      ),
    },
    {
      Header: "Submitted At",
      accessor: ({ submitted_at }) => {
        const date = moment(submitted_at);
        return (
          <Flex>
            <Text>{date.format("YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>&nbsp;{date.format("hh:mm a")}</Text>
          </Flex>
        );
      },
    },
    {
      Header: "Updated At",
      accessor: ({ updated_at }) => {
        const date = moment(updated_at);
        return (
          <Flex>
            <Text>{date.format("YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>&nbsp;{date.format("hh:mm a")}</Text>
          </Flex>
        );
      },
    },
    {
      Header: "Expires At",
      accessor: ({ expires_at }) => {
        if (!expires_at) {
          return <Text></Text>;
        }

        const date = moment(expires_at);
        return (
          <Flex>
            <Text>{date.format("YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>&nbsp;{date.format("hh:mm a")}</Text>
          </Flex>
        );
      },
    },
  ];

  const updateSearchParams = (filters: FormData) => {
    const searchParams = new URLSearchParams();

    if (filters.id !== "") {
      searchParams.set("id", filters.id);
    }

    if (filters.status !== "all") {
      searchParams.set("status", filters.status);
    }

    if (filters.side !== "all") {
      searchParams.set("side", filters.side);
    }

    if (filters.symbol !== "") {
      searchParams.set("symbol", filters.symbol);
    }

    if (filters.submitted_timeframe !== "anytime") {
      searchParams.set("submitted_timeframe", filters.submitted_timeframe);
      searchParams.set(
        "submitted_after",
        filters.submitted_after?.format("YYYY-MM-DD") ?? ""
      );
      searchParams.set(
        "submitted_until",
        filters.submitted_until?.format("YYYY-MM-DD") ?? ""
      );
    }

    setSearchParams(searchParams);
  };

  return (
    <Box>
      <OrderDetails
        isOpen={!!selectedOrder}
        onClose={() => setSelectedOrder(undefined)}
        order={selectedOrder}
        currency={currency}
      />
      <FilterDrawer
        isOpen={filterOpen}
        onClose={closeFilter}
        onApply={applyFilter}
      >
        <FilterString
          header="Order ID"
          onChange={(value) => {
            setFilterValue("id", value);
          }}
          value={filters.id}
        />
        <FilterSelect
          header="Status"
          options={ORDER_STATUSES}
          onSelect={(value) => setFilterValue("status", value)}
          selected={filters.status}
        />
        <FilterSelect
          header="Side"
          options={ORDER_SIDES}
          onSelect={(value) => setFilterValue("side", value)}
          selected={filters.side}
        />
        <FilterString
          header="Symbol"
          onChange={(value) => setFilterValue("symbol", value)}
          value={filters.symbol}
        />
        <FilterNumber
          header="Qty"
          minRange={0}
          maxRange={1000}
          step={1}
          onChange={([above, below]) => {
            setFilterValue("qty_above", above);
            setFilterValue("qty_below", below);
          }}
        />
        <FilterDateSelect
          header="Submitted At"
          onSelect={(value) => setFilterValue("submitted_timeframe", value)}
          defaultSelect={filters.submitted_timeframe}
          onDateSelect={(start, end) => {
            setFilterValue("submitted_after", start);
            setFilterValue("submitted_until", end);
          }}
        />
      </FilterDrawer>
      {filterBtn}
      <Table
        columns={columns}
        data={orders}
        isLoading={ordersQuery.isLoading}
        defaultPerPage={DEFAULT_PAGE_SIZE}
        pagination
        paginationPosition="top"
      />
    </Box>
  );
};

export default OrdersTable;
