import React, { useState, useRef } from "react";
import moment from "moment";
import { useDisclosure, useToast, Flex } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useInfiniteQuery } from "react-query";

import {
  FilterDrawer,
  FilterSelect,
  FilterButton,
  FilterDateSelect,
  dateRangeToPill,
} from "../filter";

import { getDocuments, docDownloadLink } from "../../api/api";
import { DocumentsParams, Document } from "../../api/types";
import { Column, Cell } from "react-table";
import UUID from "../general/UUID";
import { Table } from "@alpacahq/alpaca-component-library";
import { DownloadIcon } from "@chakra-ui/icons";

const DOCUMENT_TYPE_OPTIONS = [
  "account_statement",
  "trade_confirmation",
  "tax_1042s",
  "tax_1099_b_details",
  "tax_1099_b_form",
  "tax_1099_div_details",
  "tax_1099_div_form",
  "tax_1099_int_details",
  "tax_1099_int_form",
  "tax_w8",
  "tax_statement",
  "account_dump",
  "esign_agreement",
  "trulioo_transaction_record",
  "etc_form",
  "non_solicitation_form",
];
const QUERY_LIMIT = 2000;
const DEFAULT_PAGE_SIZE = 25;

interface DocumentsTableProps {
  accountID?: string;
}

interface OnFetchDataProps {
  pageIndex: number;
  pageSize: number;
}

interface FormData {
  type: string;
  created_timeframe: string;
  created_after: moment.Moment | null;
  created_until: moment.Moment | null;
}

const defaultFilters: FormData = {
  type: "All",
  created_timeframe: "anytime",
  created_after: null,
  created_until: null,
};

const createInitialFilters = (searchParams: URLSearchParams): FormData => {
  return {
    type: searchParams.get("type") ?? defaultFilters.type,
    created_timeframe:
      searchParams.get("created_timeframe") ?? defaultFilters.created_timeframe,
    created_after: searchParams.get("created_after")
      ? moment(searchParams.get("created_after"))
      : defaultFilters.created_after,
    created_until: searchParams.get("created_until")
      ? moment(searchParams.get("created_until"))
      : defaultFilters.created_until,
  };
};

const AccountDocumentsTable = ({
  accountID,
}: DocumentsTableProps): React.ReactElement => {
  const toast = useToast();
  const navigate = useNavigate();
  const skipPageResetRef = useRef(false);

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<FormData>(() =>
    createInitialFilters(searchParams)
  );

  const [appliedFilters, setAppliedFilters] = useState<FormData>(() =>
    createInitialFilters(searchParams)
  );

  const fetchDocuments = ({ pageParam = 0 }) => {
    const params: DocumentsParams = {
      limit: QUERY_LIMIT,
      offset: pageParam * pageSize,
    };

    if (appliedFilters.type !== "All") {
      params.type = appliedFilters.type;
    }

    // date filter
    if (appliedFilters.created_after && appliedFilters.created_until) {
      params.start = appliedFilters.created_after
        .startOf("day")
        .format("YYYY-MM-DD");
      params.end = appliedFilters.created_until
        .endOf("day")
        .format("YYYY-MM-DD");
    }

    return getDocuments(params, accountID);
  };

  const documentsQuery = useInfiniteQuery(
    ["documents", accountID, appliedFilters],
    fetchDocuments,
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length === QUERY_LIMIT) {
          return pages.length;
        }
      },
    }
  );

  if (documentsQuery.isError) {
    toast({
      title: "An error occurred fetching documents",
      description: (documentsQuery.error as Error).message,
      status: "error",
    });
  }

  const documents = (documentsQuery.data?.pages ?? []).flat();

  const applyFilter = () => {
    setAppliedFilters(filters);
    updateSearchParams(filters);
    onClose();
  };

  const removeFilter = (filterKey: string) => {
    setAppliedFilters((filters) => {
      const updatedFilters = { ...filters };

      if (filterKey === "type") {
        updatedFilters.type = defaultFilters.type;
      }

      if (filterKey === "created_at") {
        updatedFilters.created_timeframe = defaultFilters.created_timeframe;
        updatedFilters.created_after = defaultFilters.created_after;
        updatedFilters.created_until = defaultFilters.created_until;
      }

      updateSearchParams(updatedFilters);

      return updatedFilters;
    });
  };

  const openFilter = () => {
    setFilters(appliedFilters);
    onOpen();
  };

  const closeFilter = () => {
    onClose();
  };

  const setFilterValue = (key: keyof FormData, value: FormData[typeof key]) => {
    setFilters((filters) => ({
      ...filters,
      [key]: value,
    }));
  };

  const updateSearchParams = (filters: FormData) => {
    const searchParams = new URLSearchParams();

    if (filters.type !== "All") {
      searchParams.set("type", filters.type);
    }

    if (filters.created_timeframe !== "anytime") {
      searchParams.set("created_timeframe", filters.created_timeframe);
      searchParams.set(
        "created_after",
        filters.created_after?.format("YYYY-MM-DD") ?? ""
      );
      searchParams.set(
        "created_until",
        filters.created_until?.format("YYYY-MM-DD") ?? ""
      );
    }

    setSearchParams(searchParams);
  };

  const filterPills = {
    type: appliedFilters.type,
    created_at: dateRangeToPill([
      appliedFilters.created_after,
      appliedFilters.created_until,
    ]),
  };

  const accountColumns: Column<Document>[] = [
    {
      Header: "Account ID",
      accessor: ({ account_id }) => {
        return (
          <UUID
            value={account_id ?? ""}
            onClick={() => navigate(`/accounts/${account_id ?? ""}`)}
          />
        );
      },
    },
  ];

  const columns: Column<Document>[] = [
    {
      Header: "Document ID",
      accessor: ({ id }) => <UUID value={id} />,
    },
    ...(!accountID ? accountColumns : []),
    {
      Header: "Type",
      accessor: ({ type }) => type?.split("_").join(" ") || "-",
    },
    {
      Header: "Date",
      accessor: ({ date }) => date ?? "-",
    },
    {
      Header: " ",
      Cell: ({ row }: Cell<Document>) => (
        <DownloadIcon
          cursor="pointer"
          _hover={{ color: "blue.300" }}
          onClick={() => {
            downloadDocument(
              row.original.id,
              accountID ?? row.original.account_id
            );
          }}
        />
      ),
    },
  ];

  const downloadDocument = async (documentID: string, accountID: string) => {
    try {
      await docDownloadLink({
        document_id: documentID,
        account_id: accountID,
        redirect: false,
      });
    } catch (err) {
      toast({
        title: "Failed to download document",
        description: err.message,
        status: "error",
      });
    }
  };

  const onFetchData = ({ pageIndex, pageSize }: OnFetchDataProps): void => {
    if ((pageIndex + 1) * pageSize === documents.length) {
      documentsQuery.fetchNextPage();
      skipPageResetRef.current = true;
    }

    setPageSize(pageSize);
  };

  return (
    <>
      <FilterDrawer isOpen={isOpen} onApply={applyFilter} onClose={closeFilter}>
        <FilterSelect
          header="Document Type"
          options={["All", ...DOCUMENT_TYPE_OPTIONS]}
          onSelect={(value) => setFilterValue("type", value)}
          selected={filters.type}
        />
        <FilterDateSelect
          header="Created At"
          onSelect={(value) => setFilterValue("created_timeframe", value)}
          defaultSelect={filters.created_timeframe}
          onDateSelect={(start, end) => {
            setFilterValue("created_after", start);
            setFilterValue("created_until", end);
          }}
          value={[filters.created_after, filters.created_until]}
        />
      </FilterDrawer>
      <Flex
        mt="3rem"
        justifyContent="flex-end"
        {...(!!accountID && { mt: "-6.5rem", float: "right" })}
      >
        <FilterButton
          filterPills={filterPills}
          openFilter={openFilter}
          removeFilter={removeFilter}
        />
      </Flex>
      <Table
        columns={columns}
        data={documents}
        defaultPerPage={pageSize}
        pagination
        paginationPosition="top"
        isLoading={documentsQuery.isLoading}
        onFetchData={onFetchData}
        skipPageResetRef={skipPageResetRef}
      />
    </>
  );
};

export default AccountDocumentsTable;
