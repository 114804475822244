import React, { useCallback, useRef, useState } from "react";

import {
  Badge,
  Link,
  ThemingProps,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";

import colors from "../../components/theme/colors";

import { Table } from "@alpacahq/alpaca-component-library";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { sortBy } from "lodash";

import { listSupportTickets, ListSupportTicketsParams } from "../../api/api";
import { SupportTicket } from "../../api/types";
import { formatDate } from "../../globals/utils";

const DEFAULT_PAGE_ITEM = 100;

const STATUS_VARIANTS: Record<string, ThemingProps["colorScheme"]> = {
  new: "red",
  pending: "red",
  open: "blue",
  solved: "green",
  closed: "green",
  hold: "gray",
};

const STATUS_RANKING: { [key: string]: number } = {
  new: 1,
  pending: 2,
  open: 3,
  solved: 4,
  closed: 5,
  hold: 6,
};

interface TableProps {
  enabled: boolean;
  status?: string;
}

const TicketTable = ({ enabled, status }: TableProps): React.ReactElement => {
  const toast = useToast();
  const fetchRefId = useRef(0);
  const navigate = useNavigate();
  const skipPageResetRef = useRef(false);

  const [params, setParams] = useState<ListSupportTicketsParams>({
    page: 1,
    limit: DEFAULT_PAGE_ITEM,
  });

  const { data, isLoading } = useQuery(
    ["support-tickets", params, status],
    () => listSupportTickets({ ...params, status }),
    {
      enabled: enabled,
      onError: (err: Error) => {
        toast({
          title: "An error occurred fetching the support tickets",
          description: err.message,
          status: "error",
        });
      },
    }
  );

  const tickets = sortBy(
    data?.tickets ?? [],
    (ticket) => STATUS_RANKING[ticket.status]
  );
  const totalPages = Math.ceil((data?.count ?? params.limit) / params.limit);

  const columns: Column<SupportTicket>[] = [
    {
      id: "id",
      accessor: "id",
      Header: "Ticket #",
      Cell: ({ value }) => (
        <Link
          color={useColorModeValue(colors.yellow, colors.yellow500)}
          onClick={() => navigate(`/support/tickets/${value}`)}
        >
          {value}
        </Link>
      ),
    },
    {
      id: "subject",
      accessor: "subject",
      Header: "Subject",
    },
    {
      id: "status",
      accessor: "status",
      Header: "Status",
      Cell: ({ value }) => (
        <Badge colorScheme={STATUS_VARIANTS[value]} textTransform="capitalize">
          {value}
        </Badge>
      ),
    },
    {
      id: "requested_at",
      accessor: "requested_at",
      Header: "Requested At",
      Cell: ({ value }) => formatDate(value, "MMM DD, YYYY hh:mm A z"),
    },
    {
      id: "last_updated_at",
      accessor: "last_updated_at",
      Header: "Last Updated At",
      Cell: ({ value }) => formatDate(value, "MMM DD, YYYY hh:mm A z"),
    },
  ];

  const onFetchData = useCallback(({ pageIndex, pageSize }) => {
    if (++fetchRefId.current !== fetchRefId.current) {
      return;
    }

    skipPageResetRef.current = true;

    setParams({
      page: pageIndex + 1,
      limit: pageSize,
    });
  }, []);

  return (
    <Table
      columns={columns}
      data={tickets}
      defaultPerPage={DEFAULT_PAGE_ITEM}
      isLoading={isLoading}
      manualPagination={true}
      pagination={true}
      paginationPosition="top"
      pageCount={totalPages}
      onFetchData={onFetchData}
      skipPageResetRef={skipPageResetRef}
    />
  );
};

export default TicketTable;
